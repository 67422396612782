import { Camera } from "@evercam/shared/types/camera"
import { Project } from "@evercam/shared/types/project"

export type Router = {
  id?: number
  routerType: string
  routerUserId: string
  routerPassword: string
  serialNumber: number | null
  rmsUrl?: string
  externalHost?: string
  routerHttpPort: number | null
  vpnPassword: string
  vpnUserId: string
  vpnServer: string
  status: RouterStatus
  powerType: PowerType
  powerSchedule: string
  battery?: {
    lastVoltage: number
    lastSeen: string
  }
  sims: Array<{
    number: string
  }>
  billPayers?: Array<{
    name: string
  }>
  simProviders?: Array<{
    name: string
  }>
  projects?: Pick<Project, "exid" | "id" | "name">[]
  cameras: Pick<Camera, "id" | "name">[]
}

export enum PowerType {
  Mains = "mains",
  Battery = "battery",
  Solar = "solar",
  Generator = "generator",
}

export enum RouterStatus {
  Active = "active",
  Inactive = "inactive",
}
